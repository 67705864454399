import React, { Suspense, memo, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import PrivateRoute from '@/components/privateRoute';
import GlobalLoading from '@/components/globalLoading';
import LayoutContainer from './layout';
import routers from './utils/routes';
import LoginPage from './pages/user/login';
import ErrorBoundary from './error';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './store/reducers/common';
import commonApi from "@/api/common";
import admin from '@/api/admin';
import { useRequest } from 'ahooks';

message.config({
	top: 100,
	duration: 2,
	maxCount: 1
});

function pageRouter(item:any,userDetail:any) {
    
    // 权限
    if(item.path === "/staff" && userDetail.is_super*1 !== 1){
        return null
    }

    // 需要登录的页面
    if (!item.noLogin) {
        return <PrivateRoute
            key={item.path}
            path={item.path}
            exact={item.exact}
            routeTitle={item.title}
            component={item.component}
        />
    }
    return <Route
        key={item.path}
        path={item.path}
        exact={item.exact}
        render={(props: any) => {
            return <item.component {...props} routeTitle={item.title} />
        }}
    />
}



function Main() {

    // 状态列表
    const dispatch = useDispatch();
    const common_token = useSelector((state:any) => state.common.common_token);
    useEffect(()=>{
        if(common_token){
            try {
                dispatch(actions.setDataHandle("statusList",{sLoading:true}));
                commonApi.statusList().then((res:any={})=>{
                    let obj = {sLoading:false}
                    if(!res.error){
                        obj = {...obj,...res}
                    } 
                    dispatch(actions.setDataHandle("statusList",obj));
                })  
            } catch (error) {
                dispatch(actions.setDataHandle("statusList",{sLoading:false}));
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[common_token]);


    const [localeUser, setLocaleUser] = useState(false) as any;
    const access_token = useSelector((state:any) => state.common.access_token);
    const [userDetail,setuserDetail]:any = useState({});
    const { run: adminRun } = useRequest(admin.detail, {manual: true});
    useEffect(()=>{
        console.log("tttttt")
        if(access_token){
            try {
                adminRun().then((res={})=>{
                    setLocaleUser(true)
                    setuserDetail(res);
                })
            } catch (error) {
            } 
        }else{
            setLocaleUser(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[access_token]);

    const LayoutRouter = memo(() => (
        <LayoutContainer userDetail={userDetail}>
            <Suspense fallback={<GlobalLoading />}>
                <Switch>
                    {routers.map((item) => {
                        // 如果含有二级菜单
                        if (item.children) {
                            return item.children.map((items: any) => {
                                return pageRouter(items,userDetail)
                            })
                        }else{
                            return pageRouter(item,userDetail)
                        }
                    })}
    
                    {/* 没有匹配到的页面统一跳到404 */}
                    <PrivateRoute
                        path="/"
                        component={React.lazy(() => import(/* webpackChunkName: "result" */ './noExist'))}
                    /> 
                </Switch>
            </Suspense>
        </LayoutContainer>
    ))

    
    return (
        <ConfigProvider locale={zhCN}>
            <ErrorBoundary>
                {localeUser &&
                    <Router>
                        
                        <Switch>
                            <Route exact path='/login' component={LoginPage}></Route>
                            <Route exact path="/" render={() => <Redirect to="/forecast/list" />} />
                            <Route path="/" component={LayoutRouter}></Route>
                        </Switch>
                    </Router>
                }
            </ErrorBoundary>
        </ConfigProvider>
    );
}

export default Main;


import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

function PrivateRoute(props: any) {
	const {
		component: Component,
		routeTitle,
		...rest
    } = props;
    const access_token = useSelector((state:any) => state.common.access_token);
	return ( 
        <Route 
            {...rest}
            render = {
                (props) => {
                    return access_token ?
                        ( 
                            <Component 
                                {...props}
                                routeTitle={routeTitle}
                            />
                        ) :
                        ( 
                            <Redirect 
                                to={
                                    {
                                        pathname: "/login",
                                        state: {
                                            from: props.location
                                        }
                                    }
                                }
                            />
                        );
                }
            }
		/>
	);
}
export default PrivateRoute;

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import {message} from 'antd';
import store from '@/store';
import {actions} from '@/store/reducers/common';
const http = axios.create({
	baseURL: process.env.REACT_APP_API_PATH,
	timeout: 20000
})

http.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		config.url = config.url || '';
		if (config.url.includes('aliyuncs')) {
			config.baseURL = '';
            config.responseType = 'text';
		}else{
			// 添加token
			const access_token = localStorage.getItem('access_token');
			const common_token = localStorage.getItem('common_token');
			const token = config.url.startsWith("/c") ? common_token : access_token;
			if (token) {
				if (config.params) {
					config.params["Access-Token"] = token;
				} else {
					config.params = {
						"Access-Token": token
					};
				}
			}
			
		}
		return config
	},
	(error: AxiosError) => {
		return Promise.reject(error)
	}
)

http.interceptors.response.use(
	async (response: AxiosResponse) => {
		const res = response.data || {};
		if (res.meta && res.meta.code !== 0) {
			if (res.meta.code === 1000) {
				message.error("请重新登录"); 
				localStorage.removeItem("access_token");
				store.dispatch(actions.setDataHandle("access_token",""));
				localStorage.removeItem('account');
                store.dispatch(actions.setDataHandle("account",""));
                localStorage.removeItem('common_token');
                store.dispatch(actions.setDataHandle("common_token",""));
			}
		}
        return response;
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

export default http

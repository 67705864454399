import {cloneDeep} from "lodash-es";

const initialState = {
    access_token: localStorage.getItem('access_token'),
    account: localStorage.getItem('account'),
    common_token: localStorage.getItem('common_token'),
    statusList:{sLoading:false}, // 状态列表
    routes:[], // 打开的tab页面
    userDetail:{}
};

// action types
const types = {
    setData:"setData",
    clearData:"clearData",
};

export const actions = {
    setDataHandle:(name:any,data:any,type?:any)=>({
        type: type || types.setData,
        name,
        data
    }),
    clearData:(type?:any)=>({
        type: type || types.clearData
    })
}

// reducer
const reducer = (state:any = cloneDeep(initialState), action: any) => {
    switch (action.type) {
        case types.setData:
            return { ...state, [action.name]: action.data };
        case types.clearData:
            return { ...cloneDeep(initialState)};
        default:
            return state;
    }
};
export default reducer;
import { message } from "antd";
import {isString} from "lodash-es";
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 获取父路由路径
 *
 * @export
 * @param {*} name
 * @returns
 */
export function getSummitMenu(name:any,key:any="/",index=1) {
	if(!name || !isString(name)) {
		return "";
	}
	let pos = name.indexOf(key,index);
	return name.substring(0,pos);
};


/**
 * 获取地址参数
 *
 */
export const getQueryString = (name:string)=> { 
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
    var r = window.location.search.substr(1).match(reg); 
    if (r != null) return unescape(r[2]); 
    return null; 
}


/**
 * 随机长度字符串
 *
 * @export
 * @param {*} len
 * @returns
 */
export function randomString(len: number): string {
    return [...Array(len)].map(i=>(~~(Math.random()*36)).toString(36)).join('');
}

/**
 * 下载Excel文件
 *
 * @export
 * @param {*} href
 */
export function downloadExcelFile(href: string, filename=""): void {
	const downloadElement = document.createElement('a');
　　downloadElement.href = href;
    //downloadElement.target = '_blank';
	downloadElement.download = filename; //下载后文件名
　　document.body.appendChild(downloadElement);
　　downloadElement.click(); //点击下载
　　document.body.removeChild(downloadElement); //下载完成移除元素
}

/**
 * Antd 表单验证提示
 *
 * @export
 * @param {*} error
 */
export function formValidateError(error: any) {
	try {
		const errors = error.errorFields;
		const [f] = errors; // 优先提示第一个
		const [errorMessage] = f.errors;
		message.error(errorMessage);
	} catch (error) {}
}

/**
 * 获取文件名和后缀
 *
 * @export
 * @rule both/suffix/name
 */
export const getFileState = (name:string,rule:string="both",key=".")=>{
	if(!name || !isString(name)) { return rule==="both" ? []:"";}
	const index1=name.lastIndexOf(key);
	const index2=name.length;
	if(rule==="suffix"){
		return name.substring(1+index1,index2);
	}
	if(rule==="name"){
		return name.substring(0,index1);
	}
    return [name.substring(0,index1),name.substring(1+index1,index2)];
}

// 转为base64
export const getBase64 = (img, callback)=> {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

/**
 * 格式化utc时间
 *
 * @export
 * @param {string} date
 * @param {string} [pattern='YYYY-MM-DD HH:mm:ss']
 * @returns {string}
 */
 export function utcDate(date: any, pattern: string = 'YYYY-MM-DD HH:mm:ss'): string {
    if (!date) return '-';
    return dayjs(date*1000).tz("Europe/London").format(pattern);
}
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {getRouters} from '@/utils/routes';
import ShouldRender from '@/components/shouldRender';
import RightMenu from '../rightMenu';
import { useAliveController } from 'react-activation';
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "@/store/reducers/common";
import {cloneDeep} from 'lodash-es';
import "./index.less";

function ReuseTab(props) {
	const location = useLocation();
	const history = useHistory();
    const dispatch = useDispatch();
    const routes = useSelector((state:any) => state.common.routes);
    const { dropScope } = useAliveController();
    
	// 获取当前匹配路由信息
	const getCurrent = useCallback(() => {
        const routers = getRouters();
		const currentPath = location.pathname;
		const current = routers.find(i => {
			if (i.path === currentPath) {
				return true
			} else if(i.path === currentPath.replace(/(\d+)|(create)/img, ':id')) {
				return true;
			}
			return false
		});
		return current;
	}, [location.pathname]);

	// 关闭路由
	const closeRoute = useCallback((path, index) => {
		if (path === location.pathname) {
			const current = (index < routes.length - 1) ? routes[index + 1] : routes[index - 1];
			history.push({pathname:  current.path, search: current.search})
        }
        let arr = cloneDeep(routes);
		arr.splice(index, 1);
        dispatch(actions.setDataHandle("routes",arr));
	}, [routes, location.pathname, history,dispatch]);

	//关闭其他标签
    const closeOtherTabs = (item)=>{
        // 不是当前标签
        if (location.pathname !== item.path) {
            history.push({pathname: item.path, search:item.search});
        }
        dispatch(actions.setDataHandle("routes",[item]));
    }
    // 关闭左侧
    const closeLeftTabs = (item, index)=>{
        const newTabs = routes.slice(index);
        // 不是当前标签
        if (location.pathname !== item.path) {
            history.push({pathname:item.path, search:item.search});
        }
        dispatch(actions.setDataHandle("routes",newTabs));
    }
    // 关闭右侧
    const closeRightTabs= (item, index)=>{
        const newTabs = routes.slice(0, index + 1);
        // 不是当前标签
        if (location.pathname !== item.pathname) {
            history.push({pathname:item.path, search:item.search});
        }
        dispatch(actions.setDataHandle("routes",newTabs));
    }

    const [rightData, setRightData]:any = useState({
        rightVisible: false,
        rightEvent: null,
        rightMenu: '',
    });

    const handleMenuClick = (action, targetPath, index) => {
        if (action === 'closeOthers'){
            closeOtherTabs(targetPath);
        }
        if (action === 'closeAll'){
            goToPath('/');
        }
        if (action === 'closeLeft'){
			closeLeftTabs(targetPath, index);
        }
        if (action === 'closeRight'){
            closeRightTabs(targetPath, index);
        }
    }

	// 跳转
	const goToPath = useCallback((path, search="") => {
		if (path === '/') {
			dispatch(actions.setDataHandle("routes",[]));
		}
		history.push({pathname: path, search})
	}, [history,dispatch]);

	useEffect(() => {
        const current = getCurrent();
        if(!current){return}
        let arr = cloneDeep(routes);
		const isExist = arr.findIndex(i => i.path === location.pathname);
		if (isExist > -1) {
            // 如果search不一样，就替换
            if(arr[isExist].search !== location.search){
                arr[isExist].search = location.search;
                dispatch(actions.setDataHandle("routes",arr));
            }
            return
        };
        if (location.pathname === '/') return;
		arr.push({
			path: location.pathname,
			title: current.title,
			search: location.search,
		});
        dispatch(actions.setDataHandle("routes",arr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    //手动清除keep-alive缓存
    useEffect(() => {
        try {
            const createUrl = ['/charge/create'];
            for(let y of createUrl){
                let have = false;
                for(let i of routes){
                    if(i.path.indexOf(y) > -1){
                        have=true;
                        break;
                    }
                }
                if(!have){
                    dropScope(y)
                }
            }        
        } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routes])

	const renderRightMenu = (tab, index) => {

        const disabledClose = routes.length === 1;
		const tabIndex = routes.findIndex((item) => item.path === tab.path);
        const disabledCloseLeft = tabIndex === 0;
        const disabledCloseRight = tabIndex === routes.length - 1;

        return (
            <Menu
                selectable={false}
                onClick={({key: action}) => handleMenuClick(action, tab, index)}
            >
                <Menu.Item key="closeOthers" disabled={disabledClose}>关闭其他</Menu.Item>
                <Menu.Item key="closeAll" disabled={disabledClose}>关闭所有</Menu.Item>
                <Menu.Item key="closeLeft" disabled={disabledCloseLeft}>关闭左侧</Menu.Item>
                <Menu.Item key="closeRight" disabled={disabledCloseRight}>关闭右侧</Menu.Item>
            </Menu>
        );
    }

    const handleRightClick = (e, tab,index) => {
        e.preventDefault();
        const rightMenu = renderRightMenu(tab, index);

        setRightData({
            rightVisible: true,
            rightEvent: {clientX: e.clientX, clientY: e.clientY},
            rightMenu,
        });
	}
    const onChangeRightMenu=useCallback((rightVisible)=>{
        setRightData({rightVisible})
    },[]);
	return (
		<div className="reuse-tab">
			<RightMenu
                rightData={rightData}
                onChange={onChangeRightMenu}
            />

			<div className="reuse-tab-con">
				<ShouldRender if={routes.length > 0}>
					{routes.length > 0 && routes.map((i, index) => (
						<div 
                            className={`reuse-tab-tag ${i.path===location.pathname?'active':''}`} 
                            key={index} 
                            onContextMenu={(e) => handleRightClick(e, i, index)}
                        >
							<span onClick={() => goToPath(i.path, i.search)}>{i.title}</span>
							{ routes.length > 1 ? <CloseOutlined onClick={() => closeRoute(i.path, index)} /> : null}
						</div>
					))}
				</ShouldRender>
			</div>
		</div>
	)
}

export default ReuseTab

import http from '@/utils/http';
import { message } from 'antd';

// 列表分页
export function listTpl({url = '', params = {}}: any) {
	return new Promise(async(resolve, reject) => {
        try {
            const res = await http.get(url, {params});
            if (res.data.meta.code === 0) {
                const {data, paging, ...extra} = res.data.response;
                resolve({list: data, total: paging.total, ...extra});
            } else {
                reject({error:res.data.meta.msg});
            }
        } catch(e) {
            reject();
        }
	})
}


// 获取数据
export function getTpl({url = '', params = {}, msg=""}: any) {
	return new Promise(async(resolve, reject) => {
        try {
            const res = await http.get(url, {params});
            if (res.data.meta.code*1 === 0) {
                if(msg){
                    message.success(res.data.meta.msg);
                }
                resolve(params.hasData ? res.data.response.data : res.data.response);
            } else {
                if(msg){
                    message.error(res.data.meta.error || res.data.meta.msg);
                }
                resolve({error:res.data.meta.msg, code: res.data.meta.code});
            }
        } catch(e) {
            reject();
        }
	})
}


// post请求
export function postTpl({url, params = {}, msg = true}: any) {
    return new Promise(async(resolve, reject) => {
        try {
            const res = await http.post(url, params);
            if (res.data.meta.code*1 === 0) {
                if(msg){
                    message.success(res.data.meta.msg);
                }
                resolve(params.hasData ? res.data.response.data : res.data.response);
            } else {
                message.error(res.data.meta.error || res.data.meta.msg);
                // 此处必须为resolve，如果用reject获取不到error,也就无法知道是成功了还是失败了
                resolve({error:res.data.meta.msg, code: res.data.meta.code});
            }
        } catch(e) {
            reject();
        }
	})
}
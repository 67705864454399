
import React from 'react';
import { Dropdown, Menu} from 'antd';
import { LogoutOutlined, LockOutlined } from '@ant-design/icons';
import { Link,useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';
import admin from '@/api/admin';
import { useDispatch ,useSelector} from 'react-redux';
import {actions} from '@/store/reducers/common';


export default function LayoutHeader(props) {
    const {collapsed,collapsedHandle} = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const account = useSelector((state:any) => state.common.account);

    //退出登录
    const { run: logoutReq } = useRequest(admin.logout, {manual: true});
    const onLogout = () => {
        logoutReq().then(async(result:any)=>{
            if (result && !result.error) {
                localStorage.removeItem('access_token');
                dispatch(actions.setDataHandle("access_token",""));
                localStorage.removeItem('account');
                dispatch(actions.setDataHandle("account",""));
                localStorage.removeItem('common_token');
                dispatch(actions.setDataHandle("common_token",""));
                history.push("/login");
            }
        })
    }

    const user = (
        <Menu className="user-menu">
            <Menu.Item><Link to="/password/reset" ><LockOutlined style={{marginRight:"8px"}} /><span className="text">修改密码</span></Link></Menu.Item>
            <Menu.Item onClick={onLogout}><LogoutOutlined /><span className="text">退出登录</span></Menu.Item>
        </Menu>
    );

    return (
        <div className="layout-box">
            <div className="layout-header">
                <div className="layout-left">
                    <div onClick={collapsedHandle} className={`iconfont layout-icon  ${collapsed==="off" ? "icon-zhankai":"icon-shouqi"}`}></div>
                    <div className="layout-h"><i className="iconfont icon-guzhang"></i>{account}，欢迎回来！</div>
                </div>
                <div className="layout-right">
                    {/* <span className="layout-user layout-icon iconfont icon-Notification"></span> */}
                    <Dropdown overlay={user}>
                        <span className="layout-user layout-icon iconfont icon-ren"></span>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

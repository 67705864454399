import { getTpl, postTpl } from "./tpl"

const admin = {
    login : (params = {})=> postTpl({url: '/m/admin/login',params}),
    //用户详情
    detail : ()=> getTpl({url: '/m/admin/detail'}),
    // 退出登录
    logout : (params = {})=> postTpl({url: '/m/admin/logout', params}),
    // 修改密码
    updatePwd : (params = {})=> postTpl({url: '/m/admin/password', params}),
}
export default admin
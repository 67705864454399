import React from 'react';
import { Button } from 'antd';
/*
boundaries仅处理渲染子组件的同步错误
错误边界无法捕获以下场景中产生的错误：
事件处理（了解更多）
异步代码（例如 setTimeout 或 requestAnimationFrame 回调函数）
服务端渲染
它自身抛出来的错误（并非它的子组件）
*/
class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // 更新 state 使下一次渲染能够显示降级后的 UI
        console.log(error)
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // 可以将错误日志上报给服务器
        console.log("错误",error)
        //console.log("错误详情",errorInfo.componentStack)
    }
    render() {
        if (this.state.hasError) {
            return (
                <div className="com-page-error">
					<div className="com-page-error__wrapper">
						<h2>页面出现错误，请尝试刷新页面或者联系客服处理</h2>
						<Button type="primary" onClick={() => window.location.reload()}>刷新页面</Button>
                        <a style={{marginLeft:20}} href="/"><Button type="primary">返回首页</Button></a>
					</div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary

import React from 'react';
import {flatten} from 'lodash-es';
interface IRoute {
    title: string, // 注释
    path?: string, // 路径
    icon?:any, // 图标
    exact?: boolean,  // 是否严格匹配路径
    component?: any, // 组件
    hide?: boolean // 是否需要在侧边栏菜单显示，true不需要，false需要，默认false
    children?:any,
    authority?:string, // 是否有权限
}

const routes: Array<IRoute> = [
    {
        title: "预报",
        path: '/forecast/list',
        icon:"icon-yuyue",
        component: React.lazy(() => import(/* webpackChunkName: "forecast-list" */ '@/pages/forecast/list')),
        exact: true
    },
    {
        title: "预报详情",
        path: '/forecast/detail/:id',
        icon:"icon-yuyue",
        component: React.lazy(() => import(/* webpackChunkName: "forecast-detail" */ '@/pages/forecast/detail')),
        exact: true,
        hide:true,
    },
    {
        title: "客户",
        path: '/client',
        icon:"icon-yuangongxinxi",
        component: React.lazy(() => import(/* webpackChunkName: "client-list" */ '@/pages/client')),
        exact: true
    },
    {
        title: "员工",
        path: '/staff',
        icon:"icon-kehu",
        component: React.lazy(() => import(/* webpackChunkName: "staff-list" */ '@/pages/staff')),
        exact: true
    },
    {
        title: "账单管理",
        path: '/invoice',
        icon:"icon-yuangongxinxi",
        children:[
            {
                title: "账单列表",
                path: '/invoice/list',
                exact: true,
                component: React.lazy(() => import(/* webpackChunkName: "invoice-list" */ '@/pages/invoice'))
            },
            {
                title: '账单详情',
                path: '/invoice/detail/:id',
                hide:true,
                component: React.lazy(() => import(/* webpackChunkName: "invoice-detail" */ '@/pages/invoice/detail')),
                exact: true,
            },
            {
                title: "价格管理",
                path: '/invoice/price',
                exact: true,
                component: React.lazy(() => import(/* webpackChunkName: "price-list" */ '@/pages/price'))
            },
            {
                title: "成本管理",
                path: '/invoice/basePrice',
                exact: true,
                component: React.lazy(() => import(/* webpackChunkName: "basePrice-list" */ '@/pages/basePrice'))
            },
            {
                title: "收款账号",
                path: '/invoice/payee',
                exact: true,
                component: React.lazy(() => import(/* webpackChunkName: "payee-list" */ '@/pages/payee'))
            }
        ]
    },
    {
        title: "修改密码",
        path: '/password/reset',
        exact: true,
        hide:true,
        component: React.lazy(() => import(/* webpackChunkName: "resetPassword" */ '@/pages/user/resetPassword'))
    },
];

export function getRouters() {
	return flatten(routes.map(i => i.children ? i.children : [i]));
}

export default routes;


import React, { useState, useCallback,useEffect } from 'react'
import { Menu } from 'antd';
import routes from '@/utils/routes';
import { useLocation, useHistory } from 'react-router-dom';
import { getSummitMenu } from '@/utils/common';

export default function SideNav(props: any) {
    const {userDetail={}} = props;
    const location = useLocation();
    const history = useHistory();

    // 打开的父级菜单
    const summitMenu = getSummitMenu(location.pathname);
    const [openKeys,setOpenKeys] = useState(()=>['/invoice',(summitMenu?summitMenu:"/intra")]);

    // 打开的页面
    const [selectedKeys, setSelectedKeys] = useState(() => {
        return [location.pathname]
    });

    useEffect(() => {
        if (location.pathname !== '/') {
            setSelectedKeys([location.pathname]);
        }
    }, [location.pathname])
    
    // 跳转链接
    const goToUrl = useCallback((path) => {
        if (location.pathname === path) {
            return;
        } else {
            setSelectedKeys([path]);
            history.push(path); 
        }
    }, [location, history]);

    // 子菜单
    const subMenuItem = (item: any,icon:boolean)=>{
       // 权限
        if(item.path === "/staff" && userDetail.is_super*1 !== 1){
            return null
        }
        return (
            <Menu.Item
                key={item.path}
                onClick={() => goToUrl(item.path)}>
                {icon && <i className={`iconfont ${item.icon}`}></i>}{item.title}
            </Menu.Item>
        )
    };

    return (
        <div className="side-nav">  
            <Menu
                selectedKeys={selectedKeys}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={(keys:any)=>{
                    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
                    if (routes.map((item)=>item.path).indexOf(latestOpenKey) === -1) {
                        setOpenKeys(keys);
                    } else {
                        setOpenKeys([latestOpenKey])
                    }
                }}
            >
                {
                    routes.map((item) => item.hide ? null : (item.children ?
                        <Menu.SubMenu
                            key={item.path}
                            popupClassName="side-menu-sub"
                            title={<><i className={`iconfont ${item.icon}`}></i>{item.title}</>}>
                            {item.children.map((menuItem:any) => (
                                menuItem.hide ? null : subMenuItem(menuItem,false)
                            ))}
                        </Menu.SubMenu>
                    :
                        subMenuItem(item,true)
                    ))
                }
            </Menu>
        </div> 
    )
}

import { postTpl,getTpl } from "./tpl"

const common =  {

    //获取邮编
    zipCode: (params: any)=> postTpl({url: 'common/get-city-zip-code', params, msg: false}),

    // 获取阿里云token
    ossToken: (params: any)=> postTpl({url: '/c/ossToken', params, msg: false}),

    //状态
    statusList : (params = {})=> getTpl({url: '/c/constants', params}),

    // 客户列表
    clientList : (params) => getTpl({url:'/c/client',  params: {...params, hasData: true}}),

    // 客户列表
    plientList : (params) => getTpl({url:'/p/client/getList',  params: {...params, hasData: true}}),

    

    // 员工
    staffList : (params) => getTpl({url:'/c/staff',  params: {...params, hasData: true}}),

    // 员工角色
    staffgetList : () => getTpl({url:'/c/staff/getList',  params: {hasData: true}}),


    

    //地勤公司列表
    groundCompany : (params = {})=> getTpl({url: '/c/groundCompany', params: {...params, hasData: true}}),


}
export default common;
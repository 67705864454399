import React,{useState} from 'react';
import { Layout } from "antd";
import { usePersistFn } from 'ahooks';
import LayoutHeader from './header';
import ReuseTab from './reuseTab';
import SideNav from './sideNav';
import './index.less';

const { Content,Sider } = Layout;

function LayoutContainer(props: any) {
    
    //切换菜单
    const [collapsed,setCollapsed]:any = useState(localStorage.getItem("collapsed"));
    const collapsedHandle = usePersistFn(()=>{
        const state = collapsed === "off" ? "" : "off";
        localStorage.setItem("collapsed",state);
        setCollapsed(state);
    })


    return (
        <div className="layout-wrap">
            <Layout className={`layout ${collapsed==="off" ? "layout-menu-hide" : "layout-menu-show"}`}>
                <Sider width={220} className="layout-side">
                    <div className="logo">Clear Anco</div>
                    <SideNav  userDetail={props.userDetail || {}} />
                </Sider>
                <Content className="layout-container">
                    <LayoutHeader
                        collapsed={collapsed}
                        collapsedHandle={collapsedHandle}
                    />
                    <ReuseTab />
                    <div className="layout-container-box">
                        <div className="layout-container-con">
                            {props.children}
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default LayoutContainer


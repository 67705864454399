import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {Form, Input, Button} from 'antd';
import admin from '@/api/admin';
import { useRequest } from "ahooks";
import { formValidateError } from '@/utils/common';
import { useDispatch } from 'react-redux';
import {actions} from '@/store/reducers/common';
import logo from "./photo.png";
import './index.less';

export default function LoginPage(props: any) {
    const { history } = props;
    const dispatch = useDispatch();
    // 登录
    const { run, loading } = useRequest(admin.login, {manual: true, throwOnError: true});

    const onLogin = (params: any) => {
        run(params).then(async(result:any={})=>{
            if (!result.error) {
                localStorage.setItem('access_token', result.token);
                dispatch(actions.setDataHandle("access_token",result.token));
                localStorage.setItem('account', result.account);
                dispatch(actions.setDataHandle("account",result.account));
                localStorage.setItem('common_token', result.common_token);
                dispatch(actions.setDataHandle("common_token",result.common_token));
                history.push('/');
            }
        })
    }

    const onLoginFailed = (failed: any) => formValidateError(failed);

    return (
        <div className="login">
            <div className="login-bg"></div>
            <div className="login-box">
                <div className="login-l">
                    <img className="login-logo" src={logo} alt=""/>
                    <div className="login-h">Clear Anco</div>
                    <div className="login-h-sub">后台管理系统</div>
                </div>
                <div className="login-r">
                    <Form 
                        onFinish={onLogin} 
                        onFinishFailed={onLoginFailed}
                        layout="vertical"
                    >
                        <div className="login-title">登录</div>
                        <Form.Item
                            name="account"
                            label="账号"
                            rules={[
                                { required: true, message: "请输入账号" }
                            ]}
                        >
                            <Input className="login-input" placeholder="请输入" prefix={<UserOutlined />} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="密码"
                            rules={[{ required: true, message: "请输入密码"},]}
                        >
                            <Input className="login-input" type="password" placeholder="请输入" prefix={<LockOutlined />} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} className="login-btn">登录</Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

